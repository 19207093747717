export const LEGENDARY_GENESIS_MULTIPLIER = 3;

export const COMMON_GENESIS_MULTIPLIER = 2;

export const LEGENDARY_GENESIS= 4;

export const COMMON_GENESIS= 2;

export const TWO_GEN_2_RATE_MULTIPLIER = 1.05;

export const THREE_GEN_2_RATE_MULTIPLIER = 1.15;

export const COMMON_GEN_2 = 4;

export const LEGENDARY_GEN_2 = 8;

export const LEGENDARY_GEN_2_FALSE = 1;

export const RARE_GEN_2 = 6;

export const FUSED_GEN_2 = 16;


